export default class ParticlesDefaultConfig {
  bloodHand = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 2,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 200,
          sinX: false,
          sinY: false,
          sinXVal: {
            x: 50,
            y: 10,
          },
          sinYVal: {
            x: 50,
            y: 10,
          },
          sinXValVariance: {
            x: 100,
            y: 20,
          },
          sinYValVariance: {
            x: 100,
            y: 20,
          },
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 200,
            y: 200,
          },
          velocity: {
            x: 0,
            y: 0,
          },
          velocityVariance: {
            x: 0,
            y: 0,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: {
            x: 0,
            y: 0,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 2,
          endVariance: 2,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: {
            _r: 255,
            _g: 0,
            _b: 30,
            _alpha: 1,
          },
          end: {
            _r: 255,
            _g: 0,
            _b: 44,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          sinus: true,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 10,
        _frames: 0.9060000002384185,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['gdevelop/BloodHand.png'],
  }
  fromAtoB1 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 16, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          warp: false,
          warpSpeed: 0,
          warpBaseSpeed: 0,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          cameraZConverter: 10,
          warpFov: 20,
          warpStretch: 5,
          warpDistanceScaleConverter: 2000,
          warpDistanceToCenter: false,
          fromAtoB: true,
          fromAtoBTwoWays: true,
          pointA: { x: -300, y: 0 },
          pointB: { x: 300, y: 0 },
          thereDuration: { min: 7, max: 7 },
          thereAmplitude: { min: 220, max: 330 },
          backDuration: { min: 7, max: 7 },
          backAmplitude: { min: -220, max: -320 },
          there: { x: 'Sin', y: 'Tan', ease: 'power1.inOut' },
          back: { x: 'Sin', y: 'Tan', ease: 'power1.inOut' },
          fromAtoBOneWay: false,
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          end: { _r: 1, _g: 255, _b: 7, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 400, name: 'UniformEmission' },
      duration: -1,
      alpha: 1,
      anchor: { x: 0.5, y: 0.5 },
      blendMode: 3,
    },
    textures: ['particles/flame_01.png'],
  }
  fromAtoB2 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 16, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          warp: false,
          warpSpeed: 0,
          warpBaseSpeed: 0,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          cameraZConverter: 10,
          warpFov: 20,
          warpStretch: 5,
          warpDistanceScaleConverter: 2000,
          warpDistanceToCenter: false,
          fromAtoB: true,
          fromAtoBTwoWays: true,
          pointA: { x: -300, y: 0 },
          pointB: { x: 300, y: 0 },
          thereDuration: { min: 7, max: 7 },
          thereAmplitude: { min: 220, max: 330 },
          backDuration: { min: 7, max: 7 },
          backAmplitude: { min: -220, max: -320 },
          there: { x: 'Tan', y: 'Sin', ease: 'power1.inOut' },
          back: { x: 'Tan', y: 'Sin', ease: 'power1.inOut' },
          fromAtoBOneWay: false,
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          end: { _r: 1, _g: 255, _b: 7, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 400, name: 'UniformEmission' },
      duration: -1,
      alpha: 1,
      anchor: { x: 0.5, y: 0.5 },
      blendMode: 3,
    },
    textures: ['particles/flame_01.png'],
  }
  fromAtoB3 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 16, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          warp: false,
          warpSpeed: 0,
          warpBaseSpeed: 0,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          cameraZConverter: 10,
          warpFov: 20,
          warpStretch: 5,
          warpDistanceScaleConverter: 2000,
          warpDistanceToCenter: false,
          fromAtoB: true,
          fromAtoBTwoWays: true,
          pointA: { x: -300, y: 0 },
          pointB: { x: 300, y: 0 },
          thereDuration: { min: 7, max: 7 },
          thereAmplitude: { min: 220, max: 330 },
          backDuration: { min: 7, max: 7 },
          backAmplitude: { min: -220, max: -320 },
          there: { x: 'Sin', y: 'Sin', ease: 'power1.inOut' },
          back: { x: 'Sin', y: 'Sin', ease: 'power1.inOut' },
          fromAtoBOneWay: false,
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          end: { _r: 1, _g: 255, _b: 7, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 400, name: 'UniformEmission' },
      duration: -1,
      alpha: 1,
      anchor: { x: 0.5, y: 0.5 },
      blendMode: 3,
    },
    textures: ['particles/flame_01.png'],
  }
  fromAtoB4 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 16, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          warp: false,
          warpSpeed: 0,
          warpBaseSpeed: 0,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          cameraZConverter: 10,
          warpFov: 20,
          warpStretch: 5,
          warpDistanceScaleConverter: 2000,
          warpDistanceToCenter: false,
          fromAtoB: true,
          fromAtoBTwoWays: true,
          pointA: { x: -300, y: 0 },
          pointB: { x: 300, y: 0 },
          thereDuration: { min: 7, max: 7 },
          thereAmplitude: { min: 220, max: 330 },
          backDuration: { min: 7, max: 7 },
          backAmplitude: { min: -220, max: -320 },
          there: { x: 'None', y: 'Sin', ease: 'power1.inOut' },
          back: { x: 'None', y: 'Sin', ease: 'power1.inOut' },
          fromAtoBOneWay: false,
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          end: { _r: 1, _g: 255, _b: 7, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 400, name: 'UniformEmission' },
      duration: -1,
      alpha: 1,
      anchor: { x: 0.5, y: 0.5 },
      blendMode: 3,
    },
    textures: ['particles/flame_01.png'],
  }
  fromAtoB5 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 5, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          warp: false,
          warpSpeed: 0,
          warpBaseSpeed: 0,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          cameraZConverter: 10,
          warpFov: 20,
          warpStretch: 5,
          warpDistanceScaleConverter: 2000,
          warpDistanceToCenter: false,
          fromAtoB: true,
          fromAtoBTwoWays: true,
          pointA: { x: -300, y: 0 },
          pointB: { x: 300, y: 0 },
          thereDuration: { min: 2.4, max: 2.4 },
          thereAmplitude: { min: 120, max: 320 },
          backDuration: { min: 2, max: 2 },
          backAmplitude: { min: -120, max: -320 },
          there: { x: 'None', y: 'Sin', ease: 'back.inOut' },
          back: { x: 'None', y: 'Sin', ease: 'power1.inOut' },
          fromAtoBOneWay: false,
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          end: { _r: 1, _g: 255, _b: 7, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 400, name: 'UniformEmission' },
      duration: -1,
      alpha: 1,
      anchor: { x: 0.5, y: 0.5 },
      blendMode: 3,
    },
    textures: ['particles/dirt_01.png'],
  }
  fromAtoB6 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 5, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          warp: false,
          warpSpeed: 0,
          warpBaseSpeed: 0,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          cameraZConverter: 10,
          warpFov: 20,
          warpStretch: 5,
          warpDistanceScaleConverter: 2000,
          warpDistanceToCenter: false,
          fromAtoB: true,
          fromAtoBTwoWays: true,
          pointA: { x: -300, y: 0 },
          pointB: { x: 300, y: 0 },
          thereDuration: { min: 2.4, max: 2.4 },
          thereAmplitude: { min: 120, max: 320 },
          backDuration: { min: 2, max: 2 },
          backAmplitude: { min: -220, max: -320 },
          there: { x: 'None', y: 'Sin', ease: 'back.inOut' },
          back: { x: 'None', y: 'Sin', ease: 'bounce.inOut' },
          fromAtoBOneWay: false,
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          end: { _r: 1, _g: 255, _b: 7, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 400, name: 'UniformEmission' },
      duration: -1,
      alpha: 1,
      anchor: { x: 0.5, y: 0.5 },
      blendMode: 3,
    },
    textures: ['particles/flame_01.png'],
  }
  fromAtoB7 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 5, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          warp: false,
          warpSpeed: 0,
          warpBaseSpeed: 0,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          cameraZConverter: 10,
          warpFov: 20,
          warpStretch: 5,
          warpDistanceScaleConverter: 2000,
          warpDistanceToCenter: false,
          fromAtoB: true,
          fromAtoBTwoWays: true,
          pointA: { x: -300, y: 0 },
          pointB: { x: 300, y: 0 },
          thereDuration: { min: 2.4, max: 2.4 },
          thereAmplitude: { min: 220, max: 320 },
          backDuration: { min: 2, max: 2 },
          backAmplitude: { min: -220, max: -320 },
          there: { x: 'Cos', y: 'Sin', ease: 'back.inOut' },
          back: { x: 'Cos', y: 'Sin', ease: 'back.inOut' },
          fromAtoBOneWay: false,
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          end: { _r: 1, _g: 255, _b: 7, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 400, name: 'UniformEmission' },
      duration: -1,
      alpha: 1,
      anchor: { x: 0.5, y: 0.5 },
      blendMode: 3,
    },
    textures: ['gdevelop/Blood2.png'],
  }
  fromAtoB8 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 5, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          warp: false,
          warpSpeed: 0,
          warpBaseSpeed: 0,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          cameraZConverter: 10,
          warpFov: 20,
          warpStretch: 5,
          warpDistanceScaleConverter: 2000,
          warpDistanceToCenter: false,
          fromAtoB: true,
          fromAtoBTwoWays: true,
          pointA: { x: -300, y: 0 },
          pointB: { x: 300, y: 0 },
          thereDuration: { min: 2.4, max: 2.4 },
          thereAmplitude: { min: 220, max: 320 },
          backDuration: { min: 2, max: 2 },
          backAmplitude: { min: -220, max: -320 },
          there: { x: 'Cos', y: 'Cos', ease: 'back.inOut' },
          back: { x: 'Cos', y: 'Cos', ease: 'None' },
          fromAtoBOneWay: false,
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 29, _g: 0, _b: 0, _alpha: 1 },
          end: { _r: 62, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 400, name: 'UniformEmission' },
      duration: -1,
      alpha: 1,
      anchor: { x: 0.5, y: 0.5 },
      blendMode: 3,
    },
    textures: ['gdevelop/Trail2.png'],
  }
  warpClouds = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 10,
          timeVariance: 5,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 300,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 50,
            y: 50,
          },
          velocity: {
            x: 0,
            y: 0,
          },
          velocityVariance: {
            x: 50,
            y: 50,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: {
            x: 15,
            y: 15,
          },
          sizeEnd: {
            x: 15,
            y: 15,
          },
          startVariance: 5,
          endVariance: 5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: {
            _r: 7,
            _g: 7,
            _b: 7,
            _alpha: 1,
          },
          end: {
            _r: 49,
            _g: 1,
            _b: 49,
            _alpha: 0,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 0.2,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 10,
        _emissionRate: 2,
        _maxLife: 1,
        _emitPerSecond: 1,
        _frames: 0,
        name: 'RandomEmission',
      },
      duration: -1,
      alpha: 0.4,
      anchor: {
        x: 0.5,
        y: 0.5,
      },
      blendMode: 3,
    },
    textures: [
      'gdevelop/Water.png',
      'magic particles/magic_particles_1.png',
      'magic particles/magic_particles_5.png',
      'magic particles/magic_particles_7.png',
      'magic particles/magic_particles_9.png',
      'magic particles/magic_particles_10.png',
      'magic particles/magic_particles_18.png',
      'particles/smoke_01.png',
    ],
  }
  warpWithEffect = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 10000000,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 200,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: {
            x: 0.6,
            y: 0.6,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 70,
          warp: true,
          warpSpeed: 0.001,
          warpBaseSpeed: 0.001,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 70,
            y: 70,
          },
          velocity: {
            x: 0,
            y: 0,
          },
          velocityVariance: {
            x: 0,
            y: 0,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          cameraZConverter: 4,
          warpFov: 13,
          warpStretch: 3,
          warpDistanceScaleConverter: 7,
          warpDistanceToCenter: false,
          name: 'PositionBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 5000,
        _emissionRate: 250000,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0,
        name: 'RandomEmission',
      },
      duration: -1,
      alpha: 1,
      anchor: {
        x: 0.5,
        y: 0.5,
      },
      blendMode: 0,
    },
    textures: ['sparkle.png'],
  }
  warpWithEffectV2 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 10000000,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 200,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: {
            x: 0.6,
            y: 0.6,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 70,
          warp: true,
          warpSpeed: 0.001,
          warpBaseSpeed: 0.001,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 270,
            y: 270,
          },
          velocity: {
            x: 0,
            y: 0,
          },
          velocityVariance: {
            x: 0,
            y: 0,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          cameraZConverter: 4,
          warpFov: 13,
          warpStretch: 3,
          warpDistanceScaleConverter: 7,
          warpDistanceToCenter: true,
          name: 'PositionBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 5000,
        _emissionRate: 250000,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0,
        name: 'RandomEmission',
      },
      duration: -1,
      alpha: 1,
      anchor: {
        x: 0.5,
        y: 0.5,
      },
      blendMode: 0,
    },
    textures: ['sparkle.png'],
  }
  warp = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 10000000,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 200,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: {
            x: 0.2,
            y: 0.2,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0.1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 10,
          warp: true,
          warpSpeed: 0.001,
          warpBaseSpeed: 0.005,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 70,
            y: 70,
          },
          velocity: {
            x: 0,
            y: 0,
          },
          velocityVariance: {
            x: 0,
            y: 0,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          cameraZConverter: 5,
          warpFov: 20,
          warpStretch: 5,
          warpDistanceScaleConverter: 5,
          name: 'PositionBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 1000,
        _emissionRate: '1011111',
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0.5119999998807907,
        name: 'RandomEmission',
      },
      duration: -1,
      alpha: 1,
      anchor: {
        x: 0.5,
        y: 0.5,
      },
      blendMode: 0,
    },
    textures: ['particle.png'],
  }
  darkMagicSmoke = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 2,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 200,
          sinX: false,
          sinY: false,
          sinXVal: {
            x: 50,
            y: 10,
          },
          sinYVal: {
            x: 50,
            y: 10,
          },
          sinXValVariance: {
            x: 100,
            y: 20,
          },
          sinYValVariance: {
            x: 100,
            y: 20,
          },
          position: {
            x: 0,
            y: 150,
          },
          positionVariance: {
            x: 20,
            y: 20,
          },
          velocity: {
            x: 0,
            y: -300,
          },
          velocityVariance: {
            x: 100,
            y: 100,
          },
          acceleration: {
            x: 0,
            y: 100,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: {
            x: 1,
            y: 1,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 1,
          endVariance: 1,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 1,
          name: 'RotationBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: {
            _r: 7,
            _g: 7,
            _b: 7,
            _alpha: 1,
          },
          end: {
            _r: 49,
            _g: 1,
            _b: 49,
            _alpha: 0,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          sinus: true,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 1150,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['gdevelop/DarkMagicSmoke.png'],
  }
  darkMagicSmoke2 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 2,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 1,
          position: {
            x: 1104,
            y: 522,
          },
          positionVariance: {
            x: 5,
            y: 0,
          },
          velocity: {
            x: 0,
            y: -30,
          },
          velocityVariance: {
            x: 10,
            y: 5,
          },
          acceleration: {
            x: 0,
            y: -10,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: {
            x: 0.3,
            y: 0.3,
          },
          sizeEnd: {
            x: 0.1,
            y: 0.1,
          },
          startVariance: 0.1,
          endVariance: 0.1,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 1,
          name: 'RotationBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: {
            _r: 30,
            _g: 30,
            _b: 30,
            _alpha: 1,
          },
          end: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          sinus: true,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 20,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['gdevelop/DarkMagicSmoke.png'],
  }
  darkMagicSmoke3 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 1.5,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 1,
          position: {
            x: 674,
            y: 412,
          },
          positionVariance: {
            x: 2,
            y: 0,
          },
          velocity: {
            x: 0,
            y: -20,
          },
          velocityVariance: {
            x: 5,
            y: 5,
          },
          acceleration: {
            x: 0,
            y: -5,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: {
            x: 0.2,
            y: 0.2,
          },
          sizeEnd: {
            x: 0.1,
            y: 0.1,
          },
          startVariance: 0.1,
          endVariance: 0.1,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 1,
          name: 'RotationBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: {
            _r: 30,
            _g: 30,
            _b: 30,
            _alpha: 1,
          },
          end: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          sinus: true,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 10,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['gdevelop/DarkMagicSmoke.png'],
  }
  runes = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 2,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 400,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 50, y: 50 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 2,
          endVariance: 1,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 0, _g: 255, _b: 0, _alpha: 1 },
          end: { _r: 130, _g: 130, _b: 130, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0.5499999999999999,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['gdevelop/Runes1.png'],
  }
  trail = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 2,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 400,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: -200, y: 0 },
          positionVariance: { x: 350, y: 350 },
          velocity: { x: 200, y: 0 },
          velocityVariance: { x: 200, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 1 },
          sizeEnd: { x: 3, y: 1 },
          startVariance: 1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 248, _g: 231, _b: 28, _alpha: 1 },
          end: { _r: 130, _g: 130, _b: 130, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 10,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['gdevelop/Trail3.png'],
  }
  trail2 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 2,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          position: { x: -630, y: -270 },
          positionVariance: { x: 50, y: 50 },
          velocity: { x: 200, y: 0 },
          velocityVariance: { x: 200, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 1 },
          sizeEnd: { x: 3, y: 1 },
          startVariance: 1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 255, _b: 255, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 0.5,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['gdevelop/Trail3.png'],
  }
  magic1 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 5,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 5,
          endVariance: 5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 46, _g: 13, _b: 13, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 0.2,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['magic particles/magic_particles_20.png'],
  }
  magic2 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 3,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 5,
          endVariance: 5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 46, _g: 13, _b: 13, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 0.2,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 50,
        _frames: 0.644999998807907,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['energyball/energyball_3.png'],
  }
  magic3 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 3,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 5,
          endVariance: 5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 46, _g: 13, _b: 13, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 0.2,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 50,
        _frames: 0.644999998807907,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['flare/flare_7.png'],
  }
  magic4 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 3,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 5,
          endVariance: 5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 46, _g: 13, _b: 13, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 0.2,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 50,
        _frames: 0.644999998807907,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['others/squared_6.png'],
  }
  magic5 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 3,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 5,
          endVariance: 5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 46, _g: 13, _b: 13, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 0.2,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 50,
        _frames: 0.644999998807907,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['others/turbine_8.png'],
  }
  magic6 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 3,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 100, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 5,
          endVariance: 5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 46, _g: 13, _b: 13, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 0.2,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 50,
        _frames: 0.644999998807907,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['particles/circle_02.png'],
  }
  magic7 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 3,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 200,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 300, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 5,
          endVariance: 5,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 46, _g: 13, _b: 13, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 0.2,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 50,
        _frames: 0.644999998807907,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['particles/slash_02.png'],
  }
  magic8 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 6,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 15,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 20, y: 65 },
          positionVariance: { x: 20, y: 20 },
          velocity: { x: 30, y: 10 },
          velocityVariance: { x: 10, y: 0 },
          acceleration: { x: 0, y: -10 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 2,
          endVariance: 2,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 10, _g: 10, _b: 10, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 0.2,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 200,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['particles/fire_01.png'],
  }
  magic9 = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 6,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 15,
          sinX: false,
          sinY: false,
          sinXVal: { x: 50, y: 10 },
          sinYVal: { x: 50, y: 10 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 20, y: 65 },
          positionVariance: { x: 10, y: 10 },
          velocity: { x: 30, y: 10 },
          velocityVariance: { x: 10, y: 0 },
          acceleration: { x: 0, y: -10 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 2,
          endVariance: 2,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 80, _g: 80, _b: 80, _alpha: 1 },
          end: { _r: 1, _g: 169, _b: 185, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          rotation: 0,
          variance: 0.2,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 150,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 0.5,
      blendMode: 3,
    },
    textures: ['particles/fire_01.png'],
  }
  office = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 4.5, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 0,
          sinX: false,
          sinY: false,
          sinXVal: { x: 0, y: 0 },
          sinYVal: { x: 0, y: 0 },
          sinXValVariance: { x: 0, y: 0 },
          sinYValVariance: { x: 0, y: 0 },
          position: { x: -500, y: -330 },
          positionVariance: { x: 200, y: 0 },
          velocity: { x: 0, y: 50 },
          velocityVariance: { x: 50, y: 30 },
          acceleration: { x: 0, y: 20 },
          accelerationVariance: { x: 0, y: 10 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.04, y: 0.04 },
          sizeEnd: { x: 0.04, y: 0.04 },
          startVariance: 0.02,
          endVariance: 0.02,
          name: 'SizeBehaviour',
        },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 100, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      alpha: 1,
      blendMode: 3,
    },
    textures: ['Snow100.png'],
  }
  liquid = {
    emitterConfig: {
      behaviours: [
        {
          priority: 10000,
          enabled: true,
          maxLifeTime: 10,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          priority: 100,
          enabled: true,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 500,
            y: 500,
          },
          velocity: {
            x: 21,
            y: 21,
          },
          velocityVariance: {
            x: 87,
            y: 110,
          },
          acceleration: {
            x: -10,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: {
            x: 1,
            y: 1,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: {
            _r: 110,
            _g: 255,
            _b: 110,
            _alpha: 1,
          },
          end: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          sinus: false,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          turbulence: false,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 500,
            y: 500,
          },
          velocity: {
            x: 0,
            y: 0,
          },
          velocityVariance: {
            x: 500,
            y: 500,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          sizeStart: {
            x: 1,
            y: 1,
          },
          sizeEnd: {
            x: 1,
            y: 1,
          },
          startVariance: 0,
          endVariance: 0,
          emitPerSecond: 5,
          duration: 0,
          maxLifeTime: 3,
          vortileSize: 500,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 150,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    textures: ['cloud.png'],
  }
  firework = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 0.8,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 0,
            y: 0,
          },
          velocity: {
            x: 0,
            y: 0,
          },
          velocityVariance: {
            x: 500,
            y: 500,
          },
          acceleration: {
            x: 0,
            y: 500,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 1,
            y: 1,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          end: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          startVariance: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          endVariance: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 500,
        _frames: 0,
      },
      duration: 0.2,
    },
    textures: ['sparkle.png'],
  }
  waterTurbulence = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 1.6,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 400,
          },
          positionVariance: {
            x: 0,
            y: 0,
          },
          velocity: {
            x: -97,
            y: -800,
          },
          velocityVariance: {
            x: 80,
            y: 80,
          },
          acceleration: {
            x: 100,
            y: 600,
          },
          accelerationVariance: {
            x: 0,
            y: 150,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          sizeStart: {
            x: 0.3,
            y: 0.3,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0.2,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 0,
          },
          end: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          rotation: 0,
          variance: 3.9983747833938095,
          name: 'RotationBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          position: {
            x: -30,
            y: 200,
          },
          positionVariance: {
            x: 0,
            y: 0,
          },
          velocity: {
            x: 0,
            y: -300,
          },
          velocityVariance: {
            x: 180,
            y: 80,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          sizeStart: {
            x: 2,
            y: 2,
          },
          sizeEnd: {
            x: 2,
            y: 2,
          },
          startVariance: 0,
          endVariance: 0,
          emitPerSecond: 2,
          duration: -1,
          maxLifeTime: 1.5,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    textures: ['cloud2.png'],
  }
  water = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 1.6,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 400,
          },
          positionVariance: {
            x: 0,
            y: 0,
          },
          velocity: {
            x: -97,
            y: -800,
          },
          velocityVariance: {
            x: 80,
            y: 80,
          },
          acceleration: {
            x: 100,
            y: 600,
          },
          accelerationVariance: {
            x: 0,
            y: 150,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          sizeStart: {
            x: 0.3,
            y: 0.3,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0.2,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 0,
          },
          end: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          rotation: 0,
          variance: 3.9983747833938095,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    textures: ['cloud2.png'],
  }
  chaos = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 10,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 50,
            y: 50,
          },
          velocity: {
            x: 21,
            y: 21,
          },
          velocityVariance: {
            x: 110,
            y: 110,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 1,
            y: 1,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 110,
            _g: 255,
            _b: 110,
            _alpha: 1,
          },
          end: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 150,
        _frames: 0,
      },
      duration: -1,
    },
    textures: ['particle.png'],
  }
  fog = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 20,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: { x: -150, y: 400 },
          positionVariance: { x: 1300, y: 120 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 15, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: { x: 3, y: 3 },
          sizeEnd: { x: 3, y: 3 },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: { _r: 255, _g: 255, _b: 255, _alpha: 0 },
          end: { _r: 255, _g: 255, _b: 255, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          rotation: 0,
          variance: 0.3,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 10,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 0.05,
      blendMode: 3,
    },
    textures: ['fog.png'],
  }
  fog2 = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 4,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: { x: -400, y: 30 },
          positionVariance: { x: 300, y: 100 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 15, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: { x: 6, y: 6 },
          sizeEnd: { x: 6, y: 6 },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: { _r: 100, _g: 100, _b: 100, _alpha: 1 },
          end: { _r: 255, _g: 255, _b: 255, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          rotation: 0,
          variance: 1,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 7,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      alpha: 0.5,
      blendMode: 3,
    },
    textures: [
      'smoke/smoke2_1.png',
      'smoke/smoke2_2.png',
      'smoke/smoke2_3.png',
      'smoke/smoke2_4.png',
      'smoke/smoke2_8.png',
      'smoke/smoke2_9.png',
    ],
  }
  fire = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 2, timeVariance: 0.5, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 0, y: 200 },
          positionVariance: { x: 20, y: 20 },
          velocity: { x: 0, y: -50 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 5, y: -150 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.7, y: 0.7 },
          sizeEnd: { x: 0.3, y: 0.3 },
          startVariance: 0.3,
          endVariance: 0.3,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 2, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 200, _maxLife: 1, _emitPerSecond: 500, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 3,
    },
    textures: ['fire001.png', 'fire002.png', 'fire003.png', 'fire004.png', 'smoke1.png'],
  }
  sun = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 3, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 220,
          position: { x: 0, y: -30 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 2, variance: 3, name: 'RotationBehaviour' },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 2,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: 0, y: 0 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          sizeStart: { x: 2, y: 2 },
          sizeEnd: { x: 2, y: 2 },
          startVariance: 0,
          endVariance: 0,
          emitPerSecond: 1,
          duration: 0,
          maxLifeTime: 1,
          version: 0,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: { _maxParticles: 200, _maxLife: 1, _emitPerSecond: 1000, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 1,
    },
    textures: ['fire001.png', 'fire002.png', 'fire003.png', 'fire004.png', 'smoke1.png'],
  }
  sun2 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 3, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 220,
          position: { x: 0, y: -30 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 2, variance: 3, name: 'RotationBehaviour' },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 4,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: 0, y: -20 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          sizeStart: { x: 2, y: 2 },
          sizeEnd: { x: 2, y: 2 },
          startVariance: 0,
          endVariance: 0,
          emitPerSecond: 1,
          duration: 0,
          maxLifeTime: 1,
          version: 0,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: { _maxParticles: 200, _maxLife: 1, _emitPerSecond: 1000, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 1,
    },
    textures: ['fire001.png', 'fire002.png', 'fire003.png', 'fire004.png', 'smoke1.png'],
  }
  campFire = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 1, timeVariance: 0.5, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: -60, y: 110 },
          positionVariance: { x: 60, y: 20 },
          velocity: { x: -50, y: -100 },
          velocityVariance: { x: 0, y: 50 },
          acceleration: { x: -50, y: -100 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.7, y: 0.7 },
          sizeEnd: { x: 0.3, y: 0.3 },
          startVariance: 0.3,
          endVariance: 0.3,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 2, variance: 3, name: 'RotationBehaviour' },
        { priority: 0, enabled: true, angle: 0.5, variance: 0, name: 'EmitDirectionBehaviour' },
      ],
      emitController: { _maxParticles: 200, _maxLife: 1, _emitPerSecond: 300, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 1,
    },
    textures: ['fire001.png', 'fire002.png', 'fire003.png', 'fire004.png', 'smoke1.png'],
  }
  campFire2 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 1, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 310, y: 100 },
          positionVariance: { x: 20, y: 10 },
          velocity: { x: 0, y: -80 },
          velocityVariance: { x: 0, y: 30 },
          acceleration: { x: 0, y: 100 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.7, y: 0.7 },
          sizeEnd: { x: 0.3, y: 0.3 },
          startVariance: 0.3,
          endVariance: 0.3,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 2, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 100, _maxLife: 1, _emitPerSecond: 200, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 3,
    },
    textures: ['fire001.png', 'fire002.png', 'fire003.png', 'fire004.png', 'smoke1.png'],
  }
  campFire3 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 1, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: -67, y: 99 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: -20 },
          velocityVariance: { x: 0, y: 15 },
          acceleration: { x: 0, y: 10 },
          accelerationVariance: { x: 0, y: 0 },
          sinX: true,
          sinY: false,
          sinXVal: { x: 3, y: 0.5 },
          sinXValVariance: { x: 3, y: 0.5 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.05, y: 0.05 },
          sizeEnd: { x: 0.05, y: 0.05 },
          startVariance: 0.05,
          endVariance: 0.03,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 2, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 100, _maxLife: 1, _emitPerSecond: 100, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 3,
    },
    textures: ['fire001.png', 'fire002.png', 'fire003.png', 'fire004.png', 'smoke1.png'],
  }
  campFireSparkles = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 2, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: -100, y: 100 },
          positionVariance: { x: 60, y: 10 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 100, y: 0 },
          acceleration: { x: 0, y: -400 },
          accelerationVariance: { x: 0, y: 100 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.1, y: 0.1 },
          sizeEnd: { x: 0.05, y: 0.05 },
          startVariance: 0.05,
          endVariance: 0.025,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 255, _b: 255, _alpha: 1 },
          end: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: false,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 15,
        _frames: 0.8493499999167398,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    textures: ['cloud2.png'],
  }
  campFireSparkles2 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 1, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 310, y: 110 },
          positionVariance: { x: 30, y: 10 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 100, y: 0 },
          acceleration: { x: 0, y: -400 },
          accelerationVariance: { x: 0, y: 100 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.3, y: 0.3 },
          sizeEnd: { x: 0.2, y: 0.2 },
          startVariance: 0.05,
          endVariance: 0.025,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 255, _b: 255, _alpha: 1 },
          end: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: false,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 5,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      blendMode: 1,
    },
    textures: ['particles/trace_02.png'],
  }
  campFireTurbulence = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 1, timeVariance: 0.5, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: -60, y: 110 },
          positionVariance: { x: 60, y: 20 },
          velocity: { x: -50, y: -100 },
          velocityVariance: { x: 0, y: 50 },
          acceleration: { x: -50, y: -100 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.7, y: 0.7 },
          sizeEnd: { x: 0.3, y: 0.3 },
          startVariance: 0.3,
          endVariance: 0.3,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 2, variance: 3, name: 'RotationBehaviour' },
        { priority: 0, enabled: true, angle: 0.5, variance: 0, name: 'EmitDirectionBehaviour' },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 0,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: -100, y: 90 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: -80, y: -100 },
          velocityVariance: { x: 100, y: 50 },
          acceleration: { x: 0, y: -100 },
          accelerationVariance: { x: 0, y: 0 },
          sizeStart: { x: 2, y: 2 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0,
          endVariance: 0,
          emitPerSecond: 2,
          duration: 0,
          maxLifeTime: 2,
          version: 0,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: { _maxParticles: 200, _maxLife: 1, _emitPerSecond: 300, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 1,
    },
    textures: ['fire001.png', 'fire002.png', 'fire003.png', 'fire004.png', 'smoke1.png'],
  }
  flyingFire = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 0.5, timeVariance: 0.5, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: -300, y: -300 },
          positionVariance: { x: 20, y: 20 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 5, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.7, y: 0.7 },
          sizeEnd: { x: 0.3, y: 0.3 },
          startVariance: 0.3,
          endVariance: 0.3,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 2, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 200, _maxLife: 1, _emitPerSecond: 2000, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 3,
    },
    textures: ['fire001.png', 'fire002.png', 'fire003.png', 'fire004.png', 'smoke1.png'],
  }
  meteor = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 0.5, timeVariance: 0.5, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 1000, y: -1000 },
          positionVariance: { x: 20, y: 20 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.7, y: 0.7 },
          sizeEnd: { x: 0.3, y: 0.3 },
          startVariance: 0.3,
          endVariance: 0.3,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 2, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 200, _maxLife: 1, _emitPerSecond: 1000, _frames: 0, name: 'UniformEmission' },
      duration: 0.05,
      blendMode: 3,
    },
    textures: ['fire001.png', 'fire002.png', 'fire003.png', 'fire004.png', 'smoke1.png'],
  }
  fireWithTurbulence = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 2, timeVariance: 0.5, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 0, y: 200 },
          positionVariance: { x: 20, y: 20 },
          velocity: { x: 0, y: -50 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 5, y: -150 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.7, y: 0.7 },
          sizeEnd: { x: 0.3, y: 0.3 },
          startVariance: 0.3,
          endVariance: 0.3,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 4, name: 'RotationBehaviour' },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 0,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: 0, y: 200 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: -100 },
          velocityVariance: { x: -50, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          sizeStart: { x: 0.5, y: 0.5 },
          sizeEnd: { x: 0.5, y: 0.5 },
          startVariance: 0.5,
          endVariance: 0,
          emitPerSecond: 2,
          duration: 0,
          maxLifeTime: 2,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: { _maxParticles: 200, _maxLife: 1, _emitPerSecond: 500, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 3,
    },
    textures: ['fire001.png', 'fire002.png', 'fire003.png', 'fire004.png', 'smoke1.png'],
  }
  coinShower = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 4.4,
          timeVariance: 0.4,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 50,
            y: 20,
          },
          velocity: {
            x: 0,
            y: -1000,
          },
          velocityVariance: {
            x: 800,
            y: 0,
          },
          acceleration: {
            x: 0,
            y: 2500,
          },
          accelerationVariance: {
            x: 0,
            y: 500,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 0,
            y: 0,
          },
          sizeEnd: {
            x: 3,
            y: 3,
          },
          startVariance: 0.1,
          endVariance: 1,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 255,
            _b: 0,
            _alpha: 1,
          },
          end: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          rotation: 0,
          variance: 6,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 3,
        _emitPerSecond: 250,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: 0.3,
      animatedSprite: {
        enabled: true,
        frameRate: 15 / 60,
        loop: true,
      },
    },
    textures: ['coin_'],
  }
  fountain = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 1.4,
          timeVariance: 0.4,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 0,
            y: 0,
          },
          velocity: {
            x: 0,
            y: -142,
          },
          velocityVariance: {
            x: 50,
            y: 50,
          },
          acceleration: {
            x: 0,
            y: 200,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 0,
            y: 0,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          end: {
            _r: 255,
            _g: 212,
            _b: 169,
            _alpha: 0,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 300,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    textures: ['sparkle.png'],
  }
  flyingFountain = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 1.4,
          timeVariance: 0.4,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 0,
            y: 0,
          },
          velocity: {
            x: 0,
            y: -142,
          },
          velocityVariance: {
            x: 50,
            y: 50,
          },
          acceleration: {
            x: 0,
            y: 200,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 0,
            y: 0,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          end: {
            _r: 255,
            _g: 212,
            _b: 169,
            _alpha: 0,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 300,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    textures: ['sparkle.png'],
  }
  label = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 1.0,
          timeVariance: 0.2,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 160,
            y: 0,
          },
          velocity: {
            x: 0,
            y: 0,
          },
          velocityVariance: {
            x: 70,
            y: 40,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          end: {
            _r: 250,
            _g: 153,
            _b: 70,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 1.0,
            y: 1.0,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0.2,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          angle: 0,
          variance: 0,
          name: 'EmitDirectionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          rotation: 3.070208687183225,
          variance: 1.512976619996361,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 300,
        _emissionRate: 30,
        _emitPerSecond: 0,
        name: 'RandomEmission',
      },
      duration: -1,
    },
    textures: ['sparkle.png'],
  }
  multiplier = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 1.3,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 72.37525744460885,
            y: 186.44522549485302,
          },
          positionVariance: {
            x: 11,
            y: 11,
          },
          velocity: {
            x: 0,
            y: -68,
          },
          velocityVariance: {
            x: 100,
            y: 0,
          },
          acceleration: {
            x: 0,
            y: 170,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 1,
            y: 1,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          end: {
            _r: 255,
            _g: 159,
            _b: 0,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 1000,
        _emissionRate: 200,
        _emitCounter: 0.0003099999999584468,
        _emitPerSecond: 0,
        name: 'StandardEmission',
      },
      duration: 1.2,
    },
    textures: ['sparkle.png'],
  }
  fall = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 20,
          timeVariance: 1,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: -1000,
            y: 0,
          },
          positionVariance: {
            x: 0,
            y: 500,
          },
          velocity: {
            x: 800,
            y: 0,
          },
          velocityVariance: {
            x: 300,
            y: 300,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 0.4,
            y: 0.4,
          },
          sizeEnd: {
            x: 0.4,
            y: 0.4,
          },
          startVariance: 0.1,
          endVariance: 0.1,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          rotation: 3.14,
          variance: 1.8563321924211689,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 30,
        _frames: 0.17854999999981372,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    textures: ['leaf.png', 'leaf2.png', 'leaf4.png', 'leaf5.png'],
  }
  fallSingle = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 20,
          timeVariance: 1,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: -500,
            y: 0,
          },
          positionVariance: {
            x: 0,
            y: 500,
          },
          velocity: {
            x: 1500,
            y: 0,
          },
          velocityVariance: {
            x: 300,
            y: 300,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 1,
            y: 1,
          },
          sizeEnd: {
            x: 1,
            y: 1,
          },
          startVariance: 0.2,
          endVariance: 0.2,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          rotation: 3.14,
          variance: 1.8563321924211689,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 1,
        _frames: 0.17854999999981372,
        name: 'UniformEmission',
      },
      duration: 6,
    },
    textures: ['leaf.png', 'leaf2.png', 'leaf4.png', 'leaf5.png'],
  }
  twist = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 2.5,
          timeVariance: 1,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 17,
            y: 0,
          },
          velocity: {
            x: -148,
            y: -136,
          },
          velocityVariance: {
            x: 0,
            y: 0,
          },
          acceleration: {
            x: 85,
            y: 153,
          },
          accelerationVariance: {
            x: 0,
            y: 0,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          degrees: 120,
          degreesVariance: 100,
          maxRadius: 250,
          maxRadiusVariance: 50,
          minRadius: 40,
          minRadiusVariance: 0,
          name: 'AngularVelocityBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 0.5,
            y: 0.5,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 0.3,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          end: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          rotation: 0,
          variance: 1.1423179954302887,
          name: 'RotationBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          angle: 0,
          variance: 0,
          name: 'EmitDirectionBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 10,
        _frames: 0.16724999999976717,
        name: 'UniformEmission',
      },
      duration: 4,
    },
    textures: ['leaf.png', 'leaf2.png', 'leaf4.png', 'leaf5.png'],
  }
  fallRainDrops = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 15,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 1000,
            y: 500,
          },
          velocity: {
            x: 0,
            y: 0,
          },
          velocityVariance: {
            x: 0,
            y: 0,
          },
          acceleration: {
            x: 0,
            y: 1,
          },
          accelerationVariance: {
            x: 0,
            y: 1,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 1,
            y: 1,
          },
          sizeEnd: {
            x: 1,
            y: 1,
          },
          startVariance: 0.3,
          endVariance: 0.3,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          end: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 1,
        _frames: 0.948262999999994,
        name: 'UniformEmission',
      },
      duration: 5,
      animatedSprite: {
        enabled: true,
        frameRate: 15 / 60,
        loop: true,
        randomFrameStart: true,
      },
    },
    textures: ['coin_'],
  }
  fallRainDrops2 = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 7,
          timeVariance: 3,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 650,
            y: 350,
          },
          positionVariance: {
            x: 600,
            y: 300,
          },
          velocity: {
            x: 0,
            y: 0,
          },
          velocityVariance: {
            x: 0,
            y: 0,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          accelerationVariance: {
            x: 2,
            y: 2,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 0.15,
            y: 0.15,
          },
          sizeEnd: {
            x: 0.15,
            y: 0.15,
          },
          startVariance: 0.1,
          endVariance: 0.1,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 120,
            _g: 60,
            _b: 0,
            _alpha: 1,
          },
          end: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 1,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          sinus: true,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 10,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
      blendMode: 3,
    },
    textures: ['flare/flare_12.png'],
  }
  snow = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 8, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 0,
          position: { x: 0, y: -800 },
          positionVariance: { x: 1000, y: 0 },
          velocity: { x: 0, y: 200 },
          velocityVariance: { x: 150, y: 200 },
          acceleration: { x: 0, y: 50 },
          accelerationVariance: { x: 0, y: 50 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.1, y: 0.1 },
          sizeEnd: { x: 0.1, y: 0.1 },
          startVariance: 0.5,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 255, _b: 255, _alpha: 1 },
          end: { _r: 255, _g: 255, _b: 255, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 3, variance: 2, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 250, _frames: 0, name: 'UniformEmission' },
      duration: -1,
    },
    textures: ['snow.png'],
  }
  explosion = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 0.5, timeVariance: 0.3, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 0, y: 0 },
          positionVariance: { x: 34, y: 65 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 381, y: 384 },
          acceleration: { x: 0, y: 500 },
          accelerationVariance: { x: 0, y: 500 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 64, _b: 5, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 1, variance: 5, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 5000, _frames: 0, name: 'UniformEmission' },
      duration: 0.1,
      blendMode: 3,
      alpha: 0.3,
    },
    textures: ['fog.png', 'smokeparticle.png', 'smoke1.png', 'smoke2.png', 'smoke3.png'],
  }
  explosionForMeteor = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 0.5, timeVariance: 0.3, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: -200, y: 200 },
          positionVariance: { x: 30, y: 30 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 500, y: 500 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 250, _g: 250, _b: 7, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 1, variance: 5, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 5000, _frames: 0, name: 'UniformEmission' },
      duration: 0.2,
    },
    textures: ['fog.png', 'smokeparticle.png', 'smoke1.png', 'smoke2.png', 'smoke3.png'],
  }
  counter = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 0.4,
          timeVariance: 0.3,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 34,
            y: 28,
          },
          velocity: {
            x: 0,
            y: -200,
          },
          velocityVariance: {
            x: 600,
            y: 301,
          },
          acceleration: {
            x: 0,
            y: 1000,
          },
          accelerationVariance: {
            x: 0,
            y: 300,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 3,
            y: 3,
          },
          sizeEnd: {
            x: 0,
            y: 0,
          },
          startVariance: 2,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 0,
          },
          end: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    textures: ['flare.png'],
  }
  bigWin = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 2.3,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 0,
            y: 0,
          },
          positionVariance: {
            x: 800,
            y: 0,
          },
          velocity: {
            x: 0,
            y: -3000,
          },
          velocityVariance: {
            x: 500,
            y: 1000,
          },
          acceleration: {
            x: 0,
            y: 6000,
          },
          accelerationVariance: {
            x: 0,
            y: 3000,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 0.3,
            y: 0.3,
          },
          sizeEnd: {
            x: 1.1,
            y: 1.1,
          },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: {
            _r: 250,
            _g: 242,
            _b: 110,
            _alpha: 1,
          },
          end: {
            _r: 255,
            _g: 255,
            _b: 255,
            _alpha: 1,
          },
          startVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          endVariance: {
            _r: 0,
            _g: 0,
            _b: 0,
            _alpha: 0,
          },
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          rotation: 0,
          variance: 3.4985124856226335,
          name: 'RotationBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 200,
        _frames: 0,
        name: 'UniformEmission',
      },
      duration: 1.6,
      animatedSprite: {
        enabled: true,
        frameRate: 15 / 60,
        loop: true,
      },
    },
    textures: ['coin_'],
  }
  birds = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 20, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 450, y: 0 },
          positionVariance: { x: 0, y: 800 },
          velocity: { x: -50, y: 0 },
          velocityVariance: { x: 30, y: 10 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.3, y: 0.3 },
          sizeEnd: { x: 0.3, y: 0.3 },
          startVariance: 0.5,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 30,
        _frames: 0.2971499995328486,
        name: 'UniformEmission',
      },
      duration: -1,
      animatedSprite: {
        enabled: true,
        frameRate: 15 / 60,
        loop: true,
        randomFrameStart: true,
      },
    },
    textures: ['bird_'],
  }
  cigarette = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 2, timeVariance: 0.5, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 100, y: 60 },
          positionVariance: { x: 5, y: 5 },
          velocity: { x: 0, y: -100 },
          velocityVariance: { x: 10, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 10, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.1, y: 0.1 },
          sizeEnd: { x: 2, y: 2 },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 14, _g: 14, _b: 14, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 2, variance: 3, name: 'RotationBehaviour' },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 0,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: 100, y: 60 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: -100 },
          velocityVariance: { x: 40, y: 50 },
          acceleration: { x: 0, y: -50 },
          accelerationVariance: { x: 0, y: 20 },
          sizeStart: { x: 4, y: 4 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0,
          endVariance: 0,
          emitPerSecond: 2,
          duration: 0,
          maxLifeTime: 0,
          version: 0,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: { _maxParticles: 200, _maxLife: 1, _emitPerSecond: 300, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 3,
    },
    textures: ['smoke01_white_blurred.png'],
  }
  squareSmoke = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 2, timeVariance: 0.5, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Frame',
          radius: 200,
          position: { x: -100, y: -100 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 50, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.1, y: 0.1 },
          sizeEnd: { x: 2, y: 2 },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 14, _g: 14, _b: 14, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 2, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 200, _maxLife: 1, _emitPerSecond: 500, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      blendMode: 3,
      animatedSprite: {
        enabled: true,
        frameRate: 15 / 60,
        loop: true,
      },
    },
    textures: ['smoke01_white_blurred.png'],
  }
  ember = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 10,
          timeVariance: 0,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: {
            x: 100,
            y: 300,
          },
          positionVariance: {
            x: 500,
            y: 0,
          },
          velocity: {
            x: -100,
            y: 0,
          },
          velocityVariance: {
            x: 100,
            y: 0,
          },
          acceleration: {
            x: 0,
            y: -100,
          },
          accelerationVariance: {
            x: 0,
            y: 50,
          },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: {
            x: 0.1,
            y: 0.1,
          },
          sizeEnd: {
            x: 0.05,
            y: 0.05,
          },
          startVariance: 0.05,
          endVariance: 0.025,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 0, _b: 4, _alpha: 1 },
          end: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          angle: 0,
          variance: 0,
          name: 'EmitDirectionBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0.99,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    textures: ['cloud2.png'],
  }
  emberWithTurbulence = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 10, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 100, y: 300 },
          positionVariance: { x: 500, y: 0 },
          velocity: { x: -100, y: 0 },
          velocityVariance: { x: 100, y: 0 },
          acceleration: { x: 0, y: -100 },
          accelerationVariance: { x: 0, y: 50 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.15, y: 0.15 },
          sizeEnd: { x: 0.05, y: 0.05 },
          startVariance: 0.05,
          endVariance: 0.025,
          name: 'SizeBehaviour',
        },
        { priority: 0, enabled: true, angle: 0, variance: 0, name: 'EmitDirectionBehaviour' },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 4,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: 0, y: 500 },
          positionVariance: { x: 800, y: 0 },
          velocity: { x: -100, y: -100 },
          velocityVariance: { x: 0, y: 50 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0,
          endVariance: 0,
          emitPerSecond: 3,
          duration: 0,
          maxLifeTime: 3,
          version: 0,
          name: 'TurbulenceBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 0, _b: 4, _alpha: 1 },
          end: { _r: 255, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          sinus: false,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 30,
        _frames: 0.6096000003162771,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    textures: ['cloud2.png'],
  }
  bubbles = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 2,
          timeVariance: 1,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: { x: 0, y: 0 },
          positionVariance: { x: 20, y: 0 },
          velocity: {
            x: 0,
            y: -100,
          },
          velocityVariance: { x: 0, y: 0 },
          acceleration: {
            x: 0,
            y: 25,
          },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: { x: 0.01, y: 0.01 },
          sizeEnd: { x: 0.1, y: 0.1 },
          startVariance: 0,
          endVariance: 0.05,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: { _r: 255, _g: 255, _b: 255, _alpha: 1 },
          end: { _r: 255, _g: 255, _b: 255, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          angle: 0,
          variance: 0.5,
          name: 'EmitDirectionBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0.9935999999998602,
        name: 'UniformEmission',
      },

      duration: -1,
    },
    textures: ['Bubbles99.png'],
  }
  flyingBubbles = {
    emitterConfig: {
      behaviours: [
        {
          enabled: true,
          priority: 10000,
          maxLifeTime: 2,
          timeVariance: 1,
          name: 'LifeBehaviour',
        },
        {
          enabled: true,
          priority: 100,
          position: { x: 0, y: 0 },
          positionVariance: { x: 20, y: 0 },
          velocity: {
            x: 0,
            y: -100,
          },
          velocityVariance: { x: 0, y: 0 },
          acceleration: {
            x: 0,
            y: 25,
          },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          allowNegativeValues: false,
          sizeStart: { x: 0.01, y: 0.01 },
          sizeEnd: { x: 0.1, y: 0.1 },
          startVariance: 0,
          endVariance: 0.05,
          name: 'SizeBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          start: { _r: 255, _g: 255, _b: 255, _alpha: 1 },
          end: { _r: 255, _g: 255, _b: 255, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          name: 'ColorBehaviour',
        },
        {
          enabled: true,
          priority: 0,
          angle: 0,
          variance: 0.5,
          name: 'EmitDirectionBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0.9935999999998602,
        name: 'UniformEmission',
      },

      duration: -1,
    },
    textures: ['Bubbles99.png'],
  }
  background1 = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 8, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Rectangle',
          radius: 0,
          position: { x: -600, y: -600 },
          positionVariance: { x: 50, y: 50 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 150, y: 150 },
          acceleration: { x: 25, y: 25 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.1, y: 0.1 },
          sizeEnd: { x: 1.5, y: 1.5 },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 252, _g: 0, _b: 0, _alpha: 1 },
          end: { _r: 180, _g: 0, _b: 255, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 150, _frames: 0, name: 'UniformEmission' },
      duration: -1,
    },
    textures: ['cloud.png'],
  }
  abstractTunnel = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 5, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 80,
          position: { x: 0, y: 0 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.01, y: 0.01 },
          sizeEnd: { x: 3, y: 3 },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 0, _g: 213, _b: 255, _alpha: 0 },
          end: { _r: 0, _g: 213, _b: 255, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: true,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, angle: 0, variance: 0.5, name: 'EmitDirectionBehaviour' },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 3,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: 20, y: 20 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          sizeStart: { x: 5, y: 5 },
          sizeEnd: { x: 0.5, y: 0.5 },
          startVariance: 0,
          endVariance: 0,
          emitPerSecond: 1,
          duration: 0,
          maxLifeTime: 1,
          version: 0,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 500, _frames: 0, name: 'UniformEmission' },
      duration: -1,
    },
    textures: ['cloud.png'],
  }
  warpOut = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 2, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 0, y: 0 },
          positionVariance: { x: 500, y: 500 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 110, _g: 255, _b: 110, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 2,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: 0, y: 0 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          sizeStart: { x: 9, y: 9 },
          sizeEnd: { x: 9, y: 9 },
          startVariance: 0,
          endVariance: 0,
          emitPerSecond: 13,
          duration: 0,
          maxLifeTime: 1,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 150, _frames: 0, name: 'UniformEmission' },
      duration: -1,
    },
    textures: ['cloud.png'],
  }
  warpIn = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 2, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 0, y: 0 },
          positionVariance: { x: 800, y: 800 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 110, _g: 255, _b: 110, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 5,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: 0, y: 0 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          sizeStart: { x: 9, y: 9 },
          sizeEnd: { x: 9, y: 9 },
          startVariance: 0,
          endVariance: 0,
          emitPerSecond: 13,
          duration: 0,
          maxLifeTime: 1,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 150, _frames: 0, name: 'UniformEmission' },
      duration: -1,
    },
    textures: ['cloud.png'],
  }
  blackHoles = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 2, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 0, y: 0 },
          positionVariance: { x: 800, y: 800 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 110, _g: 255, _b: 110, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 5,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: 0, y: 0 },
          positionVariance: { x: 800, y: 800 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 15,
          endVariance: 15,
          emitPerSecond: 1,
          duration: 0,
          maxLifeTime: 1,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 150, _frames: 0, name: 'UniformEmission' },
      duration: -1,
    },
    textures: ['cloud.png'],
  }
  warmOut = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 2, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 0, y: 0 },
          positionVariance: { x: 800, y: 800 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 110, _g: 255, _b: 110, _alpha: 1 },
          end: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          showVortices: false,
          effect: 3,
          turbulence: false,
          vortexOrgSize: 128,
          position: { x: 0, y: 0 },
          positionVariance: { x: 800, y: 800 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 0, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          sizeStart: { x: 3, y: 3 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 15,
          endVariance: 15,
          emitPerSecond: 1,
          duration: 0,
          maxLifeTime: 1,
          name: 'TurbulenceBehaviour',
        },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 150, _frames: 0, name: 'UniformEmission' },
      duration: -1,
    },
    textures: ['cloud.png'],
  }
  bubbleSpray = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 1, timeVariance: 0.5, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 0, y: 200 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: -300 },
          velocityVariance: { x: 40, y: 0 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0, y: 0.01 },
          sizeEnd: { x: 0.1, y: 0.1 },
          startVariance: 0,
          endVariance: 0.5,
          name: 'SizeBehaviour',
        },
        { priority: 0, enabled: true, rotation: 2, variance: 3, name: 'RotationBehaviour' },
        {
          priority: 0,
          enabled: true,
          start: { _r: 255, _g: 255, _b: 255, _alpha: 1 },
          end: { _r: 255, _g: 255, _b: 255, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 1 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0.5 },
          sinus: false,
          name: 'ColorBehaviour',
        },
      ],
      emitController: {
        _maxParticles: 30,
        _emissionRate: '200',
        _emitCounter: 0.007854999986011498,
        _maxLife: 1,
        _emitPerSecond: 200,
        _frames: 0,
        name: 'RandomEmission',
      },
      duration: 0.5,
    },
    textures: ['Bubbles99.png'],
  }
  cartoonSmoke = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 1, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 0, y: 0 },
          positionVariance: { x: 0, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 500, y: 500 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 1, y: 1 },
          sizeEnd: { x: 1, y: 1 },
          startVariance: 0,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          start: { _r: 160, _g: 102, _b: 0, _alpha: 1 },
          end: { _r: 255, _g: 221, _b: 144, _alpha: 0 },
          startVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          endVariance: { _r: 0, _g: 0, _b: 0, _alpha: 0 },
          sinus: false,
          name: 'ColorBehaviour',
        },
        { priority: 0, enabled: true, rotation: 3, variance: 3, name: 'RotationBehaviour' },
      ],
      emitController: {
        _maxParticles: 50,
        _emissionRate: '1001',
        _emitCounter: 0.0008899500607440726,
        _maxLife: 1,
        _emitPerSecond: 100,
        _frames: 0,
        name: 'StandardEmission',
      },
      duration: 1,
    },
    textures: ['CartoonSmoke.png'],
  }
  explodingBubbles = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 5, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          position: { x: 0, y: 300 },
          positionVariance: { x: 500, y: 0 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 100, y: 0 },
          acceleration: { x: 0, y: -50 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.1, y: 0.1 },
          sizeEnd: { x: 0.5, y: 0.5 },
          startVariance: 0.05,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        { priority: 0, enabled: true, angle: 0, variance: 0, name: 'EmitDirectionBehaviour' },
        { priority: 0, enabled: true, rotation: 0, variance: 1, name: 'RotationBehaviour' },
      ],
      emitController: {
        _maxParticles: 0,
        _maxLife: 1,
        _emitPerSecond: 30,
        _frames: 0.5949000001419336,
        name: 'UniformEmission',
      },
      duration: -1,
    },
    finishingTextures: ['pop_00.png', 'pop_01.png', 'pop_02.png'],
    textures: ['Bubbles99.png'],
  }
  coinsRing = {
    emitterConfig: {
      behaviours: [
        { priority: 10000, enabled: true, maxLifeTime: 10, timeVariance: 0, name: 'LifeBehaviour' },
        {
          priority: 100,
          enabled: true,
          spawnType: 'Ring',
          radius: 300,
          sinX: true,
          sinY: true,
          sinXVal: { x: 200, y: 20 },
          sinYVal: { x: 200, y: 40 },
          sinXValVariance: { x: 100, y: 20 },
          sinYValVariance: { x: 100, y: 20 },
          position: { x: 0, y: 0 },
          positionVariance: { x: 50, y: 50 },
          velocity: { x: 0, y: 0 },
          velocityVariance: { x: 110, y: 110 },
          acceleration: { x: 0, y: 0 },
          accelerationVariance: { x: 0, y: 0 },
          name: 'PositionBehaviour',
        },
        {
          priority: 0,
          enabled: true,
          allowNegativeValues: false,
          sizeStart: { x: 0.1, y: 0.1 },
          sizeEnd: { x: 0, y: 0 },
          startVariance: 0.1,
          endVariance: 0,
          name: 'SizeBehaviour',
        },
        { priority: 0, enabled: true, rotation: 0, variance: 2, name: 'RotationBehaviour' },
      ],
      emitController: { _maxParticles: 0, _maxLife: 1, _emitPerSecond: 150, _frames: 0, name: 'UniformEmission' },
      duration: -1,
      animatedSprite: {
        enabled: true,
        frameRate: 15 / 60,
        loop: true,
        randomFrameStart: true,
      },
    },
    textures: ['coin_'],
  }
}
